.form-phone {
    color: white;
    background-color: #2e2e2e;
    width: 500px;
    height: 65px;
    border-radius: 32px;
    text-align: center;
    margin: auto auto;
}

.form-phone a:link {
    text-decoration: none;
    color: white;
}

.form-phone a:visited {
    text-decoration: none;
    color: white;
}

.form-phone a:hover {
    text-decoration: underline;
    color: white;
}

.form-phone a:active {
    text-decoration: underline;
    color: white;
}

form {
    max-width: 450px;
}