.delivery-action-button button img {
    width: 50px;
    height: 50px;
}

.delivery-action-button button {
    width: 270px;
    height: 120px;
    background-color: #414141;
    border-color: #414141;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
}

.delivery-action-button button:hover {
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.delivery-action-button button:active {
    transform: translateY(1px);
}