$apple-red: #f20036;

$link-color: $apple-red;
$link-color-dark: $apple-red;
$primary: $apple-red;
$primary-dark: $apple-red;

// this is to ensure that the primary text colour uses white as the background
// https://stackoverflow.com/questions/68298372/customize-bootstrap-5-text-color-contrast-in-button/68303393#68303393
// most evident in the submit buttons
$min-contrast-ratio: 3;

@import "../../node_modules/bootstrap/scss/bootstrap";

.navbar {
  background-color: $apple-red !important;
}
