.order-header {
  color: white;
  background-color: #414141;
}

.order-header a:link {
  text-decoration: none;
  color: white;
}

.order-header a:visited {
  text-decoration: none;
  color: white;
}

.order-header a:hover {
  text-decoration: none;
  color: rgb(201, 198, 198);
}

.order-header a:active {
  text-decoration: none;
  color: white;
}