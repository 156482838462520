.nav-link:link {
    color: white;
}

.nav-link:visited {
    color: white;
}

.nav-link:hover {
    color: rgb(201, 198, 198);
}

.nav-link:active {
    color: rgb(201, 198, 198);
}