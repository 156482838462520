.btnTrackPackage {
    width: 200px;
    height: 50px;
    background: #F20036;
    border: none;
    font-weight: 600;
    color: white;
    font-size: 14px;
    font-weight: bold;
    transition: 0.4s;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
}

.btnTrackPackage:hover {
    background-color: #9c0425;
}

.btnTrackPackage:link {
    text-decoration: none;
    color: white;
}

.btnTrackPackage:visited {
    text-decoration: none;
    color: white;
}

.btnTrackPackage:hover {
    text-decoration: none;
    color: white;
}

.btnTrackPackage:active {
    text-decoration: none;
    color: white;
}