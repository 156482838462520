.question {
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  position: relative;
}

[data-bs-theme=dark] .question {
  background-color: rgb(69, 69, 69);
  color: rgb(255, 255, 255);
}

[data-bs-theme=light] .question {
  background-color: rgb(245, 245, 245);
  color: rgb(0, 0, 0);
}

.question label {
  font-size: 18px;
  display: block;
}

.arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  transition: transform 0.3s ease;
}

.arrow.active {
  transform: translateY(-50%);
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  border-radius: 4px;
  position: relative;
}

.answer.active {
  max-height: 200px;
}